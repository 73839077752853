import { createGlobalStyle } from "styled-components";
import HelveticaNowTextMedium from "../fonts/HelveticaNowText-Medium.ttf";

const GlobalStyle = createGlobalStyle`

export default fonts {
  @font-face {
  font-family: 'HelveticaNowText-Medium';
  src: url(${HelveticaNowTextMedium}) format('ttf')
}

}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
	background-color: #fff;
	background-color: #efeded;
}
  
  body {
    /* background-color: #4b4949; */
    /* font-family: 'Poppins', sans-serif; */
    font-family: 'HelveticaNowText-Medium', sans-serif;
  }

  h1 {
    /* font-size: 7vw; */
    font-size: 7vw;
    font-weight: 400;
  }

  h2 {
    font-size: 5.5vw;
    font-weight: 400;
  }

  h3 {
    font-size: 5vw;
    font-weight: 400;
  }

  a {
    text-decoration: none;
    color: #000;
  }
  li {
    list-style-type: none;
  }
  .container {
    width: 90vw;
    margin: 0 auto;
    max-width: 1800px;
  }

  img {
    width: 100%;
    height: 100%;
  }
  /* button, input[type="submit"] {
    cursor: pointer;
    border: none;
    text-align: center;
    background-color: #a7a6a6;
    border-radius: 2px;
    text-transform: uppercase;
    transition: background 350ms ease-in-out, 
                transform 250ms ease;
    &:hover {
    background-color: #4e4e4e;
    color: #fff;
    }
  } */
  .spinner {
    position: absolute;
    width: 10%;
    height: 10%;
    z-index: 1000;
    left: 50%;
    top: 50%;
  }

  .btn {
  padding: 10px 25px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
}

.btn {
  border: 2px solid rgb(0, 0, 0);
  z-index: 1;
  color: #000;
}

.btn:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  direction: ltr;
  z-index: -1;
  background: rgb(0, 0, 0);
  transition: all 0.3s ease;
}

.btn:hover {
  color: rgb(255, 255, 255);
}

.btn:hover:after {
  right: auto;
  left: 0;
  width: 100%;
}

.btn:active {
  top: 2px;
}

`;

export default GlobalStyle;
