import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Menu from './components/Menu';
import About from './pages/About';
import Email from './pages/Email';
import Home from './pages/Home';
import Project from './pages/Project';
import Projects from './pages/Projects';
import GlobalStyle from './styles/GlobalStyle';
import Fonts from './styles/GlobalStyle'


function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <Fonts />
      {/* <Home /> */}
      <BrowserRouter>
        {/* <Navbar/> */}
        <Menu />


          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/projects' element={<Projects />} />
            <Route path='/project/:url' element={<Project />} />
            <Route path='/email' element={<Email />} />
            <Route path='/about' element={<About />} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
