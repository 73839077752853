import { Link } from "react-router-dom";
import styled from "styled-components";
import { projects, websites } from "../data";

const Projects = () => {
  return (
    <Wrapper>
      <div
        id="projects"
        className={
          window.location.pathname === "/projects"
            ? "container"
            : "container-main-page"
        }
      >
        <div className="projects-container">
          <ul className="project-links">
            {projects.map((item) => (
              <li key={item.id}>
                <Link to={`/project/${item.url}`}>
                  <img src={item.img} alt={item.title} />
                  <p>{item.title}</p>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="websites-container">
          <ul className="project-links">
            {websites.map((item) => (
              <li
                key={item.id}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(`${item.website}`, "_blank");
                }}
              >
                {item.title}
                <p className="go-to-web-text">GO TO WEBSITE</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Wrapper>
  );
};

export default Projects;

const Wrapper = styled.div`
  /* height: 100vh;
  width: 100vw; */

  /* background-color: #ebedf1; */
  .container-main-page {
    width: 90vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 80px;
  }

  .container {
    height: 100vh;
    width: 90vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .projects-container,
  .websites-container {
    width: 100%;
  }

  .project-links {
    position: relative;
  }

  li {
    //margin-bottom: 20px;
    border-top: 1px solid #000;
    padding: 20px 0;
    font-size: clamp(18px, 3.2vw, 42px);

    &:last-child {
      /* border-bottom: 1px solid #000; */
      border-bottom: none;
      //padding-bottom: 40px;
    }

    a {
      font-size: clamp(32px, 4vw, 52px);
      font-size: clamp(18px, 3.2vw, 42px);
      line-height: 1.1;
      transition: transform 0.3s cubic-bezier(0.55, 0.55, 0.675, 0.19);
    }

    &:hover {
      cursor: pointer;

      img {
        opacity: 1;
        transform: translateY(-70%) translateX(30px);
      }
    }

    img {
      opacity: 0;
      pointer-events: none;
      aspect-ratio: 16/9;
      display: block;
      width: 30vw;
      height: 100%;
      position: absolute;
      top: 50%;
      right: 10%;
      object-fit: contain;
      transform: translateY(-70%) translateX(-30px);
      transition: transform 0.5s ease-in-out;
      //filter:  brightness(1.3) grayscale(0.54) contrast(0.8) saturate(1.2);
    }
  }

  .websites-container {
    .go-to-web-text {
      /* padding: 20px 0; */
      border-top: none;
      font-size: clamp(18px, 3.2vw, 42px);
    }

    .go-to-web-text {
      opacity: 0;
      transform: translateX(-30px);
      transition: all 0.5s ease-in-out;
      font-size: 16px;
    }
    ul {
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-child {
          border-bottom: 1px solid #000;
          //padding-bottom: 40px;
        }
        a {
        }
        &:hover {
          .go-to-web-text {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }
    }
  }
`;
