import React from "react";
import styled from "styled-components";
import { ReactComponent as Arrow } from "../images/Arrow.svg";
import { motion, useScroll, useTransform } from "framer-motion";
import { Link } from "react-router-dom";

function Contact() {
  const { scrollYProgress } = useScroll();
  // const x = useTransform(scrollYProgress, [0, 1], [0, -70]);
  const x = useTransform(scrollYProgress, [1, 0], [0, -2020]);

  return (
    <Wrapper style={{ x }}>
      <div className="container">
        <div className="arrow-wrapper">
          <Arrow />
        </div>
        <div className="links-wrapper">
          <Link to="/email">
            <h3 className="link">email</h3>
          </Link>
          <a
            href="https://www.linkedin.com/feed/"
            target="_blank"
            rel="noreferrer"
          >
            <h3 className="link">linkedin</h3>
          </a>
        </div>
      </div>
    </Wrapper>
  );
}

export default Contact;

const Wrapper = styled(motion.div)`
  width: 90vw;
  margin: 0 auto;
  height: 85vh;
  height: 65vh;
  transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1),
    cubic-bezier(0.165, 0.84, 0.44, 1);
  @media (max-width: 860px) {
    height: 25vh;
  }

  .container {
    display: flex;
    width: 90vw;
    margin: 0 auto;
    margin-left: 0;
    height: 100%;
    justify-content: space-between;
    align-items: center;

    .arrow-wrapper {
      width: 30vw;
      height: 30vw;
    }
  }

  .links-wrapper {
    display: flex;
    flex-direction: column;
    .link {
      line-height: 1.3;
      display: inline-block;
      position: relative;
    }
  }

  .link::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 4px;
    bottom: 0;
    left: 0;
    background-color: #a32427;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .link:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;
