import React from "react";
import styled from "styled-components";

function About() {
  return (
    <Wrapper>
      <div className="col-left">
        <div className="ball"></div>
        <h1>about</h1>
      </div>
      <div className="col-right">
        <div className="text-container">
          <p>
            As a Frontend Developer, I bring passion and expertise to every
            project I work on. With two of years of experience under my belt, I
            have honed my skills in HTML, CSS, JavaScript, React, WordPress and
            working with APIs, and I'm always eager to tackle new challenges and
            learn something new. I enjoy designing websites with end users in
            mind.
          </p>
          <br />
          <p>
            I believe that a website should not only look great, but also
            provide a seamless user experience. I pay close attention to detail
            and make sure that every aspect of my projects meets the highest
            standards. My experience with React and APIs allows me to build
            dynamic and responsive websites that can efficiently handle large
            amounts of data.
          </p>
          <br />
          {/* <p>
            When I'm not coding, you can find me exploring new design trends,
            playing with new technologies, or simply tinkering with code for
            fun. I'm confident in my abilities as a Junior Frontend Developer,
            and I'm always eager to bring your ideas to life.
          </p>
          <br /> */}
          <p>
            With hands-on experience in HTML, CSS, JavaScript, React, and APIs,
            I've brought my frontend development skills to life by delivering
            successful projects for real clients. If you're seeking a Junior
            Frontend Developer who is eager to bring your ideas to life with
            passion, creativity, and technical know-how, I'd love the
            opportunity to discuss your project with you
          </p>
        </div>
        <div className="wrapper">
          <p className="heading">The portfolio was created using:</p>
          <div className="bottom-content">
            <ul>
              <li>React</li>
              <li>Styled Componets</li>
              <li>Framer Motion</li>
              <li>GSAP GreenSock</li>
              <li>EmailJS</li>
              <li>React Hook Form</li>
              <li>Yup</li>
            </ul>
            <ul>
              <li>Fonts:</li>
              <li>HelveticaNowText</li>
            </ul>
            <ul className="colors-list">
              <li>Colors:</li>
              <li className="bg-color"></li>
              <li className="color-red"></li>
              <li className="ball-color"></li>
              <li className="black-color"></li>
            </ul>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default About;

const Wrapper = styled.div`
  display: flex;
  width: 90vw;
  /* margin: 5vw auto; */
  margin-top: 5vw;
  margin-bottom: 2vw;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 900px) {
    flex-direction: column;
    height: 600px;
  }
  .col-left {
    display: flex;
    height: 42.5vw;
    justify-content: space-between;
    flex-direction: column;
    flex: 50%;
    @media (max-width: 1380px) {
      height: 49vw;
    }
    @media (max-width: 1250px) {
      height: 47vw;
    }
    .ball {
      background-color: #4a4a4a;
      height: 18vw;
      width: 18vw;
      border-radius: 50%;
      @media (max-width: 900px) {
        display: none;
      }
    }
    h1 {
      font-size: 15vw;
      line-height: 0.7;

      @media (max-width: 900px) {
        height: 200px;
        display: flex;
        align-items: center;
      }
    }
  }
  .col-right {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 50%;
    .text-container {
      max-width: 70%;
      margin: 0 auto;
      @media (max-width: 900px) {
        max-width: 100%;
      }
      p {
        font-size: 16px;
        @media (max-width: 1250px) {
          font-size: 13px;
        }
        @media (max-width: 900px) {
          max-width: 100%;
        }
      }
    }
    .bottom-content {
      display: flex;
      justify-content: space-between;

      width: 70%;
      margin: 0 auto;
      @media (max-width: 900px) {
        width: 100%;
      }
      @media (max-width: 420px) {
        flex-direction: column;
        > * {
          margin-bottom: 30px;
        }
      }
      ul > li {
        font-size: 16px;
        @media (max-width: 1250px) {
          font-size: 13px;
        }
      }
      .colors-list {
        li {
          margin-bottom: 3px;
        }
      }
    }
    .heading {
      margin-bottom: 33px;
      max-width: 70%;
      margin: 0 auto;
      height: 60px;

      @media (max-width: 1250px) {
        font-size: 13px;
        margin-bottom: 18px;
      }
      @media (max-width: 900px) {
        max-width: 100%;
        margin: 30px 0;
        height: 0;
      }
    }
    .bg-color {
      width: 40px;
      height: 14px;
      background-color: #efeded;
      border: 1px solid #18181814;
      &::after {
        content: "#efeded";
        margin-left: 50px;
        font-size: 14px;
      }
    }
    .color-red {
      width: 40px;
      height: 15px;
      background-color: #a32427;
      &::after {
        content: "#a32427";
        margin-left: 50px;
        font-size: 14px;
      }
    }
    .ball-color {
      width: 40px;
      height: 15px;
      background-color: #4a4a4a;
      &::after {
        content: "#4a4a4a";
        margin-left: 50px;
        font-size: 14px;
      }
    }
    .black-color {
      width: 40px;
      height: 15px;
      background-color: #000;
      &::after {
        content: "#000000";
        margin-left: 50px;
        font-size: 14px;
      }
    }
  }
`;
