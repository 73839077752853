import React from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { projects } from "../data";
import { UnderLine } from "../components/UnderLine";

function Project() {
  const { url } = useParams();
  const navigate = useNavigate();
  return (
    <Wrapper>
      <div className="project-container">
        {projects
          .filter((item) => item.url === url)
          .map((item, index) => (
            <li key={index}>
              {/* <h2>About project</h2> */}
              <div className="cols-wrapper">
                <div className="col-left">
                  <div className="image-wrapper">
                    <img src={item.img} alt={item.title} />
                  </div>
                </div>
                <div className="col-right">
                  <div className="wrapper">
                    <div className="title-wrapper">
                      <h1>{item.title}</h1>
                    </div>
                    <div className="description-wrapper">
                      <p className="description">{item.description}</p>
                      <UnderLine />
                    </div>
                    <div className="box-links">
                      <div className="links">
                        <a
                          className="link"
                          href={item.website}
                          target="_blank"
                          rel="noreferrer"
                        >
                          website
                        </a>
                        <a
                          className="link"
                          href={item.github}
                          target="_blank"
                          rel="noreferrer"
                        >
                          github
                        </a>
                      </div>
                      <div className="close-project">
                        <button className="btn" onClick={() => navigate(-1)}>back</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
      </div>
    </Wrapper>
  );
}

export default Project;

const Wrapper = styled.div`
  height: 100vh;
  width: 90vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 860px) {
    height: auto;
    /* background-color: #8e8e8e; */
  }

  .project-container {
    .cols-wrapper {
      display: flex;
      width: 100vw;
      justify-content: space-between;
      align-items: center;
      /* height: 50vh; */
      /* background-color: #333; */

      @media (max-width: 860px) {
        display: flex;
        flex-direction: column;
      }
    }
    .col-left {
      width: 50%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      height: 80vh;
      background-color: #8e8e8e;

      @media (max-width: 860px) {
        width: 90vw;
        height: auto;
        background-color: transparent;
      }

      .image-wrapper {
        height: 50%;
        margin: auto;
        display: block;

        @media (max-width: 860px) {
          width: 100%;
          height: 100%;
          object-fit: contain;
          margin-top: 90px;
        }
      }
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 65%;
    }

    .close-project {
      margin-left: auto;
    }

    .col-right {
      width: 50%;
      height: 80vh;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /* background-color: #777; */
      background-color: #fbfbfb;

      @media (max-width: 860px) {
        width: 90vw;
        height: 70vh;
        background-color: transparent;
      }

      .title-wrapper {
        position: relative;
      }
      .description-wrapper {
        position: relative;
      }

      .description {
        max-width: 30vw;

        @media (max-width: 860px) {
          max-width: 100%;
        }
      }
      .box-links {
        display: flex;
        .links {
          display: flex;
          flex-direction: column;
          line-height: 1.3;
          a:hover {
            color: #a32427;
          }
        }
      }

      .link {
        line-height: 1.3;
        display: inline-block;
        position: relative;
      }

      .link::after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #a32427;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }

      .link:hover::after {
        
        transform: scaleX(1);
        transform-origin: bottom left;
      }

      .close-project {
        margin-top: auto;
        button {
          cursor: pointer;
        }
      }
    }
  }
`;
