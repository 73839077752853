import React from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import styled from "styled-components";

function ProjectsBanner() {
  const { scrollYProgress } = useScroll();
  const x = useTransform(scrollYProgress, [0, 1], [0, -270]);

  return (
    <Wrapper>
      <Title className="test" style={{ x }}>
        projects
      </Title>
    </Wrapper>
  );
}

export default ProjectsBanner;

const Wrapper = styled.div`
  height: 80vh;
  width: 100vw;
  /* background-color: #777; */
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 860px) {
    height: 200px;
    /* justify-content: flex-end; */
  }
`;

const Title = styled(motion.h1)`
  font-size: 20vw;
  margin: 0;
  white-space: nowrap;
  /* margin-top: auto; */
  transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1),
    cubic-bezier(0.165, 0.84, 0.44, 1);

  @media (max-width: 860px) {
    /* height: 15vh; */
    margin-top: 0;
    margin-left: auto;
  }
`;
