import React from "react";
import styled from "styled-components";

function Scroll() {
  return (
    <Wrapper>
      <div className="arrow-container">
        <div className="arrow"></div>
        {/* <span className="text">scmmroll</span> */}
      </div>
    </Wrapper>
  );
}

export default Scroll;

const Wrapper = styled.div`
  .arrow-container {
    display: flex;
    width: 100%;
    height: 100%;
    .arrow {
      display: inline-block;
      position: relative;
      padding-top: 79px;
      text-align: center;
    }
    .arrow::before {
      animation: elasticus 2.2s cubic-bezier(1, 0, 0, 1) infinite;
      position: absolute;
      top: 53px;
      left: 50%;
      margin-left: -1px;
      width: 1px;
      height: 40px;
      background: #000000;
      content: " ";
    }

    .arrow::after {
      .text {
        content: "";
        display: block;
        background-color: #782b2b;
        width: 200px;
        height: 100px;
      }
    }
  }

  @keyframes elasticus {
    0% {
      transform-origin: 0% 0%;
      transform: scale(1, 0);
    }
    50% {
      transform-origin: 0% 0%;
      transform: scale(1, 1);
    }
    50.1% {
      transform-origin: 0% 100%;

      transform: scale(1, 1);
    }
    100% {
      transform-origin: 0% 100%;
      transform: scale(1, 0);
    }
  }
`;
