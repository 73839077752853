import React from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import styled from 'styled-components';

function ContactBanner() {

  const { scrollYProgress } = useScroll();
  const x = useTransform(scrollYProgress, [0, 1], [-800, 0]);

  return (
    <Wrapper>
      <Title style={{ x }}>contact</Title>
    </Wrapper>
  )
}

export default ContactBanner;

const Wrapper = styled.div`
  /* height: 50vh; */
  width: 90vw;
  margin: 0 auto;
  /* background-color: #777; */
  /* display: flex;
  align-items: center;
  justify-content:center; */
  @media (max-width: 860px) {
      /* height: 400px;
      height: 300px; */
    }
    
    `;

const Title = styled(motion.h1)`
  font-size: 20vw;
  margin: 0;
  white-space: nowrap;
  margin-top: auto;

  transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1), cubic-bezier(0.165, 0.84, 0.44, 1);
  @media (max-width: 860px) {
      height: 150px;
      margin-top: 40px;
  }
`;