import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styled from "styled-components";

function AboutSection() {
  gsap.registerPlugin(ScrollTrigger);

  const timelineRef = useRef();

  const containerRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const subtitleRef2 = useRef(null);
  const subtitleRef3 = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const title = titleRef.current;
    const subtitle = subtitleRef.current;
    const subtitle2 = subtitleRef2.current;
    const subtitle3 = subtitleRef3.current;

    if (!container) {
      return;
    }

    // Create timeline sync'd to the viewport scroll.
    if (!timelineRef.current) {
      timelineRef.current = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          pin: true,
          scrub: 3,
          start: "top center",
          // end: '+=100%',
          end: "center +=30%",
          // markers: true,
          // toggleClass: 'active'
        },
      });
    }

    // Include all animate-able elements here.
    if (title) {
      timelineRef.current.fromTo(
        title,
        {
          // opacity: 0,
          // y: -20,
        },
        {
          opacity: 1,
          // y: 0,
          duration: 2,
        },
        ">"
      );
    }

    if (subtitle) {
      timelineRef.current.fromTo(
        subtitle,
        {
          // opacity: 0,
          // y: -20,
          duration: 4,
        },
        {
          opacity: 1,
          // y: 0,
          duration: 2,
        },
        ">"
      );
    }
    if (subtitle2) {
      timelineRef.current.fromTo(
        subtitle2,
        {
          // opacity: 0,
          // y: -20,
          duration: 4,
        },
        {
          opacity: 1,
          // y: 0,
          duration: 2,
        },
        ">"
      );
    }
    if (subtitle3) {
      timelineRef.current.fromTo(
        subtitle3,
        {
          // opacity: 0,
          // y: -20,
          duration: 4,
        },
        {
          opacity: 1,
          // y: 0,
          duration: 2,
        },
        ">"
      );
    }

    // Add a delay at the end of the animation before the next section scrolls into view.
    timelineRef.current.addPause("+=2");
  }, []);

  // const containerClassName = classnames({
  //   container: true,
  //   blue: theme === 'blue',
  // });

  return (
    <Wrapper>
      <div className="about-section">
        <div className="text-container">
          {/* <p>TEXT</p> */}
          <div ref={containerRef}>
            {/* <p ref={titleRef} className="title">
              Maximizing your online impact with bespoke, visually stunning
              websites.
            </p> */}
            <p ref={subtitleRef} className="subtitle">
              As a Frontend Developer, I combine my technical prowess and design
              acumen to deliver standout projects.
            </p>
            <p ref={subtitleRef2} className="subtitle">
              I bring technical expertise and an eye for design to every
              project.
            </p>
            {/* <p ref={subtitleRef3} className="subtitle">
              Let's join forces and build something extraordinary.
            </p> */}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default AboutSection;

const Wrapper = styled.div`
  .about-section {
    /* height: 100vh; */
    width: 90vw;
    margin: 0 auto;
    /* background-color: #636161; */
    display: flex;
    align-items: center;

    @media (max-width: 860px) {
      position: relative;
      display: block;
    }

    .text-container {
      /* width: 50%; */
      max-width: 40%;

      @media (max-width: 880px) {
        mix-blend-mode: difference;
        background-color: #414141;
        background-color: #6d6b6bc4;
        max-width: 100%;
        height: 630px;
        position: relative;
      }

      p {
        font-size: 25px;
        /* background-color: #fff; */
        margin-bottom: 20px;
        @media (max-width: 860px) {
          font-size: 16px;

          max-width: 80vw;
          margin: 5px auto;
        }
      }
      /* .active {
      color: red
    } */
    }

    .title,
    .subtitle {
      opacity: 0;
    }
  }
  .active {
    color: red;
  }
`;
