import React, { useRef } from "react";
import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Modal from "../components/Modal";
import styled from "styled-components";
import AnimatedTextWord from "../animation/animation";

const schema = yup.object().shape({
  to_name: yup.string().required("name is a required field").min(3),
  user_email: yup.string().email().required("email is a required field"),
  message: yup.string().required().min(20),
});

const Email = () => {
  const form = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowModal(false), 3000);
    return () => clearTimeout(timer);
  }, [showModal]);

  const sendEmail = () => {
    emailjs
      .sendForm(
        "service_58436a6",
        "template_ug6iawl",
        form.current,
        "LV0f4BZc_NAinb2u-"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          setShowModal(true);
          reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div style={{ backgroundColor: "#e1dfdd" }}>
      <Wrapper>
        <ColRight>
          <div className="container">
            <AnimatedTextWord
              text="Get in touch"
              style={{ position: "relative", zIndex: 9999 }}
            />
            <div className="half-circle">
              <div className="half-circle-bottom"></div>
            </div>
          </div>
        </ColRight>
        <ContactForm>
          <form ref={form} onSubmit={handleSubmit(sendEmail)}>
            <input {...register("to_name")} placeholder="name" name="to_name" />
            <ErrorMsg>{errors.to_name?.message}</ErrorMsg>
            <input
              {...register("user_email")}
              placeholder="email"
              name="user_email"
            />
            <ErrorMsg>{errors.user_email?.message}</ErrorMsg>
            <textarea
              {...register("message")}
              placeholder="message"
              name="message"
            />
            <ErrorMsg>{errors.message?.message}</ErrorMsg>
            <div className="bottom-wrapper">
              <input type="submit" value="Send" />
              <div className={showModal ? "modal" : ""}>
                {showModal && <Modal>Your message has been send</Modal>}
              </div>
            </div>
          </form>
        </ContactForm>
      </Wrapper>
    </div>
  );
};

export default Email;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;

  width: 70vw;

  margin: 0 auto;
  height: 100vh;
  @media (max-width: 860px) {
    width: 0;
    height: auto;
    /* display: block; */
    /* width: auto; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ContactForm = styled.div`
  width: 400px;
  width: 22vw;
  display: flex;
  align-items: center;
  @media (max-width: 868px) {
    width: 100vw;
    height: 65vh;
    height: 55vh;
    align-items: flex-end;
    position: relative;
  }

  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
    /* padding: 55px; */
    @media (max-width: 868px) {
      width: 60vw;
    }

    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border: none;
      border-bottom: 1px solid rgb(31, 30, 30);
      background-color: #e1dfdd;

      &:focus {
        border-bottom: 2px solid rgb(26, 26, 26);
      }
    }

    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border: none;
      border-bottom: 1px solid rgb(31, 30, 30);
      background-color: #e1dfdd;
      &:focus {
        border-bottom: 2px solid rgb(21, 21, 21);
      }
    }

    label {
      margin-top: 1rem;
    }

    input[type="submit"] {
      margin-top: 1.8vw;
      cursor: pointer;
      background: rgb(115 115 127);
      color: white;
      border: transparent;
      transition: all ease-in-out 0.6s;
      -webkit-appearance: none;
      border-radius: 0;

      &:hover {
        border: 1px solid #000;
        background-color: transparent;
        color: rgb(249, 105, 14);
      }
    }

    .bottom-wrapper {
      width: 100%;
    }
    .modal {
      @media (max-width: 860px) {
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translate(-50%, 0);
        margin-top: 0;
        border: none;
      }
    }
  }
`;

const ColRight = styled.div`
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  width: 50%;

  height: 100vh;
  align-items: center;
  /* background-color: #e7e4e4;   */
  position: relative;

  width: 30vw;
  margin-right: auto;
  /* xxxxxxxxxxxxxxxxxxxxx */
  @media (max-width: 860px) {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container {
    display: flex;
    position: relative;
    /* width: 440px;
    height: 440px; */
    width: 25vw;
    height: 25vw;
    border-radius: 15rem;
    background: radial-gradient(
        63.62% 69.52% at 100% 0%,
        rgba(247, 214, 98, 0.8) 0%,
        rgba(247, 214, 98, 0.168) 52.08%,
        rgba(247, 214, 98, 0) 100%
      ),
      linear-gradient(
        208.42deg,
        #f0422a 7.46%,
        rgba(240, 88, 42, 0.18) 42.58%,
        rgba(240, 101, 42, 0) 64.13%
      ),
      radial-gradient(
        114.51% 122.83% at 0% -15.36%,
        #e74f6a 0%,
        rgba(231, 79, 106, 0.22) 66.72%,
        rgba(231, 79, 106, 0) 100%
      ),
      linear-gradient(
        333.95deg,
        rgba(83, 208, 236, 0.85) -7.76%,
        rgba(83, 208, 236, 0.204) 19.67%,
        rgba(138, 137, 190, 0) 35.42%
      ),
      radial-gradient(
        109.15% 148.57% at 4.46% 98.44%,
        #1b3180 0%,
        rgba(27, 49, 128, 0) 100%
      ),
      linear-gradient(141.57deg, #4eadeb 19.08%, rgba(78, 173, 235, 0) 98.72%);
    background-blend-mode: normal, normal, normal, normal, multiply, normal;
    /* filter: blur(50px); */
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    /* z-index: 999; */

    /* .half-circle {
      width: 20vw;
      height: 10vw;
      background-color: #a32427;
      border-radius: 10vw 10vw 0 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    } */
    @media (max-width: 860px) {
      position: absolute;
      top: 10%;
      left: 50%;
     
    }

    .half-circle-bottom {
      /* width: 30vw;
    height: 15vw; */
      width: 25vw;
      height: 12.5vw;
      border-radius: 0 0 30vw 30vw;
      background-color: #362e2e6a;
      position: absolute;
      left: 50%;
      bottom: -25%;
      transform: translate(-50%, -50%);
    }
    @media (max-width: 860px) {
      display: block;
    }
  }
`;

const ErrorMsg = styled.p`
  color: #f50404;
  display: inline-block;
  margin-bottom: 20px;
  font-size: 12px;
`;
