import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Ellipse1 } from "../images/Ellipse1.svg";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import AboutSection from "../components/AboutSection";
import ProjectsBanner from "../components/ProjectsBanner";
import Projects from "./Projects";
import ContactBanner from "../components/ContactBanner";
import Contact from "./Contact";
import Scroll from "../components/Scroll";

function Home() {
  gsap.registerPlugin(ScrollTrigger);

  const timelineRef = useRef();
  const sectionRef = useRef(null);
  const ellipseRef = useRef(null);

  useEffect(() => {
    const section = sectionRef.current;
    const ellipseAnimation = ellipseRef.current;

    timelineRef.current = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: "top",
        toggleActions: "restart pause reverse pause",
        scrub: true,
      },
      pin: true,
      end: "bottom +=790%",
    });

    if (ellipseAnimation) {
      timelineRef.current.fromTo(
        ellipseAnimation,
        {
          y: 0,
        },
        {
          y: 650,
        },
        ">"
      );
    }
  }, []);

  const paragrathRef = useRef(null);

  useEffect(() => {
    const paragraf = paragrathRef.current;
    timelineRef.current = gsap.timeline({
      scrollTrigger: {
        trigger: paragraf,
        toggleActions: "restart pause reverse pause",
        start: "top center",
        end: "bottom ",
        scrub: true,
        toggleClass: { targets: paragraf, className: "active2" },
      },
      pin: true,
    });
  });

  return (
    <Wrapper>
      <div className="div-container">
        <div className="box box1"></div>
        <div d={1} className="box box1"></div>
        <div d={0.55} className="box outline" ref={sectionRef}>
          <div className="box box2">
            <p className="heading-text-1">Łukasz Kaminski</p>
            <br />
            <p className="heading-text-2">Frontend Developer</p>
            <br />
            <p className="heading-text-3">Based in Oslo</p>
            <br />
            <Scroll />
            <p
              style={{
                transform: "rotate(90deg) translate(4vw)",
                transformOrigin: "7px 0",
              }}
            >
              scroll
            </p>
          </div>
        </div>
        <div className="box outline"></div>
        <div className="box outline"></div>
        <div className="box outline"></div>
        <div className="box outline"></div>
        <div className="box outline"></div>
        <div className="boxes">
          <div d={-0.75} className="box outline">
            <div
              className="box box3a"
              style={{ height: "100vh" }}
              ref={ellipseRef}
            >
              <Ellipse1 className="ellipse"></Ellipse1>
              <p ref={paragrathRef} className="text-in-svg">
                hello
              </p>
            </div>
          </div>
        </div>
        <div d={-0.085} className="box outline">
          <div className="box box4"></div>
        </div>
      </div>
      <AboutSection />
      <ProjectsBanner />
      <Projects />
      <ContactBanner />
      <Contact />
      <div className="outro" />
    </Wrapper>
  );
}
export default Home;

const Wrapper = styled.div`
  /* background-color: #e1dfdd; */
  */ .intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50vh;
    padding: 0 2rem;
    position: absolute;
    color: #fff;
    height: 100px;
    z-index: 999;
  }

  .div-container {
    display: flex;
    justify-content: space-evenly;
    max-height: 100vh;
    /* background-color: #afa7a2; */
  }

  .box {
    width: 23vw;
    height: 100vh;
  }

  .box1 {
    /* background-color: #afa7a2; */
    height: 20vh;
    h4 {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 33px;
    }
    width: 50vw;
    .heading {
      text-align: center;
      width: 30%;
      height: 50vw;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      h3 {
        width: 90vw;
        background-color: #fff;
        margin: 0 auto;
        display: flex;
        text-align: center;
      }
    }
  }

  .box2 {
    /* background-color: #afa7a2; */
    height: 110vh;
    width: 25vw;
    width: 32vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 0.5;
    transform: translateX(-40%);
    @media (max-width: 860px) {
      width: 40vw;
      /* height: 70vh; */
      mix-blend-mode: difference;
      z-index: 9;
      position: relative;
    }

    .heading-text-1 {
      font-size: 28px;
      margin-bottom: 10px;
      margin-bottom: 20px;

      @media (max-width: 860px) {
        font-size: 20px;
        margin-bottom: 10px;
        color: #fff;
      }
      @media (max-width: 468px) {
        font-size: 18px;
        margin-bottom: 12px;
      }
    }

    .heading-text-2 {
      font-size: 20px;
      margin-bottom: 5px;
      @media (max-width: 860px) {
        font-size: 18px;
        color: #fff;
      }
      @media (max-width: 468px) {
        font-size: 16px;
      }
    }
    .heading-text-3 {
      font-size: 16px;
      margin-bottom: 8px;
      @media (max-width: 860px) {
        font-size: 16px;
        color: #fff;
      }
      @media (max-width: 468px) {
        font-size: 14px;
      }
    }

    .bbb {
      display: flex;
      justify-content: flex-start;
      /* align-items: flex-end; */
      align-items: center;
      height: 115%;
      font-size: 30px;
      /* background-color: #918484; */
    }
  }

  .box4 {
    /* background-color: #afa7a2; */
    width: 15vw;
    display: flex;
    justify-content: center;
  }

  .box3 {
    /* background-color: #afa7a2; */
    /* transform: translateY(0%); */
    background-color: #888;
  }

  .box3a {
    /* transform: translateY(75%); */
    /* background-color: #777; */
    position: relative;

    @media (max-width: 860px) {
      /* position: absolute;
      left: 10%;
      transform: translate(-10%, -10%); */
      /* width: 95%; */
    }
  }

  .ellipse {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-bottom: 20px;
    @media (max-width: 1300px) {
      width: 25vw;
    }
    @media (max-width: 860px) {
      width: 55vw;
      position: absolute;
      transform: translate(-50%, 0%);
      transform: translate(-70%, 0%);
    }
  }
  .text-in-svg {
    color: red;
    font-size: 2.5vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    transition: 0.8s;
    @media (max-width: 860px) {
      display: none;
    }
  }

  .active2 {
    color: green;
    opacity: 0;
  }

  .box.outline {
    background: transparent;
    /* background: #0e0d0de1; */
  }
`;
