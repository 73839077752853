import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import useLockBodyScroll from "../hooks/useLockBodyScroll";
import { motion } from "framer-motion";

function Menu() {
  const [click, setClick] = useState(false);
  const handleClick = () => {
    setClick(!click);
  };

  function StopScroll() {
    useLockBodyScroll();
    return <p></p>;
  }

  const animation = {
    exit: {
      opacity: 0,
      height: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.6,
        delay: 0.9,
      },
    },
  };

  return (
    <>
      <MenuWrapper onClick={handleClick} />
      <NavBackground clicked={click} />
      <Background clicked={click} />
      <Navigation onClick={handleClick} clicked={click}>
        {click && (
          <List
            variants={{ animation }}
            initial={{ width: 0, opacity: 0 }}
            animate={{ width: "20vh", opacity: 1 }}
            transition={{ duration: 0.5 }}
            exit={{
              opacity: 1,
              x: 90,
              transition: {
                ease: "easeInOut",
                delay: 0.5,
              },
            }}
          >
            <ItemLink to="/">
              <li>
                <span data-content="Home" aria-hidden="true"></span>Home
              </li>
            </ItemLink>
            <ItemLink to="projects">
              <li>
                <span data-content="Projects" aria-hidden="true"></span>Projects
              </li>
            </ItemLink>
            <ItemLink to="about">
              <li>
                <span data-content="About" aria-hidden="true"></span>About
              </li>
            </ItemLink>
            <ItemLink to="email">
              <li>
                <span data-content="Email" aria-hidden="true"></span>Email
              </li>
            </ItemLink>
          </List>
        )}
      </Navigation>
      {click && <StopScroll />}
    </>
  );
}

export default Menu;

const MenuWrapper = styled.div`
  position: fixed;
  top: 5%;
  right: 5%;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background-color: #a32427;
  cursor: pointer;
  z-index: 999939;
`;

const NavBackground = styled.div`
  position: fixed;
  top: 5%;
  right: 5%;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  /* background-color: #a32427; */
  /* opacity: 0.7; */
  z-index: 889;

  transform: ${(props) => (props.clicked ? "scale(100)" : "scale(0)")};
  /* transition: transform 1s; */

  /* background-color: ${(props) => (props.clicked ? "#555" : "#987345")}; */
  background-color: ${(props) => (props.clicked ? "#000" : "#a32427")};
  transition: all 0.5s;
`;

const Background = styled.div`
  position: fixed;
  top: 20%;
  left: 7%;
  height: 30px;
  width: 30px;
  border-radius: 80%;
  z-index: 889;
  transform: ${(props) => (props.clicked ? "scale(60)" : "scale(0)")};
  opacity: ${(props) => (props.clicked ? "1" : "0")};
  background-color: ${(props) => (props.clicked ? "#555" : "#987345")};
  transition: all 0.3s;
  @media (max-width: 860px) {
    background-color: #000;
  }
`;

const Navigation = styled.div`
  height: 30vh;
  z-index: 99999559;
  position: fixed;
  top: 0%;
  right: 0;
  pointer-events: none;

  /* z-index:999; */
  /* width: 10%; */
  /* transform: translate(10%); */
  /* pointer-events: none; */
  /* background-color: red; */
  /* opacity: ${(props) => (props.clicked ? "1" : "0")}; */
  width: ${(props) => (props.clicked ? "10%" : "0")};
  /* transition: opacity 1.2s; */
`;

const List = styled(motion.ul)`
  position: absolute;
  top: 150%;
  right: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  /* width: 100%; */
  @media (max-width: 860px) {
    right: 100%;
  }
`;

const ItemLink = styled(NavLink)`
  color: #fff;
  font-size: 60px;
  pointer-events: auto;

  li {
    position: relative;
    display: inline-block;
    color: #a32427;
    // text-decoration: none;
    overflow: hidden;
    span {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      transform: translateX(-100%);
      transition: transform 375ms ease;
      &::before {
        display: inline-block;
        content: attr(data-content);
        color: initial;
        transform: translateX(100%);
        transition: transform 375ms ease;
      }
    }
    &:hover {
      span {
        transform: translateX(0);
        opacity: 0.6;
        &::before {
          transform: translateX(0);
        }
      }
    }
  }
`;
